<template>
  <path d="M11.583 0v2.236H0V0h11.583zm0 5.764V8H0V5.764h11.583z" fill="#000" />
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
